<script>
import { mapMutations } from "vuex";
import art from "@/components/art/art.vue";
export default {
  components: { art },
  data() {
    return {
      year: null,
      loginMessage: "Bienvenido a",
      restorePassMessage: "Para proteger tu cuenta, queremos asegurarnos de que realmente seas tú la persona que intenta acceder.",
    };
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    isLogin() {
      return this.currentPath.includes("/login");
    },
  },
  methods: {
    ...mapMutations("user", { setQueryUser: "setQueryUser", setAuthMethods: "setAuthMethods" }),
    goBack() {
      if (this.$route.path.includes("auth/restore")) {
        this.setQueryUser(null);
        this.setAuthMethods([]);
        this.$router.push("auth/login");
      } else {
        this.$router.back(-1);
      }
    },
    deleteItemArt() {
      const artElement = document.querySelector(".authcomponent__artBottom");
      this.$nextTick(() => {
        artElement.classList.remove();
      });
    },
  },
  mounted() {
    const today = new Date();
    const year = today.getFullYear();
    this.year = year;
  },
};
</script>
<template>
  <main class="authcomponent">
    <div class="authcomponent__card">
      <art class="authcomponent__artTop" />
      <art class="authcomponent__artBottom" dir="bottom" />
      <div class="authcomponent__section authcomponent__section--left">
        <div class="authcomponent__return" @click="goBack()" v-if="!isLogin"></div>
        <div class="authcomponent__titleContainer">
          <div class="authcomponent__titleRestore" v-if="!isLogin">
            <h1 class="authcomponent__mainTitle">Models 1A</h1>
            <p class="authcomponent__messageBelow">{{ this.restorePassMessage }}</p>
          </div>
          <div class="authcomponent__titleLogin" v-if="isLogin">
            <h2 class="authcomponent__secondaryText">{{ this.loginMessage }}</h2>
            <h1 class="authcomponent__mainTitle">Models 1A</h1>
          </div>
        </div>
      </div>
      <div class="authcomponent__section authcomponent__section--right">
        <router-view></router-view>
      </div>
    </div>
    <div class="authcomponent__footer">
      <div class="authcomponent__locations">
        <p>Medellín - Pereira - Cúcuta - Bogotá - Global</p>
      </div>
      <div class="authcomponent__reservedRight">
        <p>Models1A © Todos los derechos reservados {{ this.year }}</p>
      </div>
    </div>
  </main>
</template>
<style lang="scss">
.Vue-Toastification {
  &__toast--error {
    color: $lipstick;
    background-color: #fff;
    font-family: $newFont_regular;
    overflow: hidden;

    div,
    button {
      color: $lipstick;
    }

    .Vue-Toastification__progress-bar {
      background-color: $lipstick;
    }
  }

  &__toast--success {
    color: #52a656;
    background-color: #fff;
    font-family: $newFont_regular;

    div,
    button {
      color: #52a656;
    }

    .Vue-Toastification__progress-bar {
      background-color: #52a656;
    }
  }
}

.authcomponent {
  * {
    text-transform: none;
  }

  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../assets/img/Fondo.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0px;

  &__card {
    position: relative;
    width: 90vw;
    height: 100vh;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    overflow: hidden;
    justify-content: center;
  }

  &__artTop,
  &__artBottom {
    max-width: 370px;
    transform: scale(0.7);
    z-index: 1;
  }

  &__artTop {
    left: -15px;
  }

  &__artBottom {
    right: 0;
    margin: 0 auto;
  }

  .art[dir="bottom"] {
    transform: scale(0.7) scaleX(-1) rotateX(180deg);
  }

  &__section {
    z-index: 3;
  }

  &__return {
    padding: 16px;
    position: absolute;
    width: 30px;
    left: 10px;
    top: 20px;
    border-radius: 10px;
    transition: all ease-in 0.2s;
    background-image: url("../../assets/img/ReturnDefault.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &:hover {
      background-image: url("../../assets/img/Return.svg");
      background-color: #ebebeb;
    }
  }

  &__titleRestore {
    @include Flex(column);
    margin-top: 10%;
  }

  &__titleLogin {
    @include Flex(column);
  }

  &__mainTitle {
    color: rgb(189, 9, 9);
    font-size: 50px;
    font-family: $third-font;
    font-weight: 400;
  }

  &__secondaryText {
    font-family: $newFont_semiBold;
    font-size: 24px;
    width: 50%;
    color: rgb(0, 0, 0);
    text-align: center;
    width: 100%;
  }

  &__messageBelow {
    font-size: 15px;
    width: 80%;
    text-align: justify;
    font-weight: 400;
    color: rgb(0, 0, 0);
    font-family: $newFont_regular;
    margin-top: 16px;
  }

  &__section--right{
    overflow-y: auto;
  }

  &__footer {
    width: 90vw;
    max-width: 1200px;
    @include Flex(column, center, center);
    gap: 4px;
    text-align: center;
    font-family: $newFont_regular;
  }

  :-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #919191;
  }

  @include tabletWidth() {
    height: 100vh;

    &__card {
      padding-top: 0;
      width: 90vw;
      height: 70vh;
      flex-direction: row;
    }

    &__titleContainer {
      height: 100%;
      @include Flex(row, center, center);
    }

    &__secondaryText {
      width: 100%;
      text-align: left;
    }

    &__titleRestore {
      margin-top: 0%;
    }

    &__messageBelow {
      width: 50%;
    }

    &__artTop {
      left: 20px;
      top: -400px;
    }

    &__artBottom {
      left: 55%;
      right: 0;
    }

    &__section--left {
      width: 50%;
      height: 100%;
      box-shadow: none;
      flex-direction: column;
    }

    &__return {
      top: 20px;
      left: 20px;
    }

    &__section--right {
      width: 50%;
      margin-top: 25px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__footer {
      flex-direction: row;
      justify-content: space-between;
      padding: 10 16px;
    }
  }

  @include screen_tv() {
    &__card {
      min-width: 300px;
      max-width: 1350px;
      height: 70%;
      width: 100%;
    }

    &__artBottom {
      left: 65%;
    }

    &__section--left {
      .authcomponent__mainTitle {
        font-size: 60px;
      }
    }
  }
}
</style>
